






























































































































































































































































































































































































































@import "~quasar-variables";

.list {
  .q-table__top {
    padding: 0;
  }

  tbody {
    tr {
      cursor: pointer;

      &:nth-child(even) {
        background-color: transparentize($light-grey, 0.5);
      }
    }
  }

  .with-row-actions {
    tbody {
      tr {
        cursor: default;
      }
    }
  }
}
