








































@import "~quasar-variables";

.user-card {
  border-radius: $generic-border-radius;
  max-width: 350px;

  &.small {
    max-width: 150px;
  }
}
